<template>
  <div class="hopper">
    <!-- 标题栏 -->
    <div class="title">
      <!-- 图标信息 -->
      <div>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-hopper"></use>
        </svg>
      </div>
      <!-- 文字标题 -->
      <div class="textTitle">
        <div>
          <!-- 销售漏斗 -->
          {{ $t("label.stdashbord.xsld.title") }}
        </div>
        <div class="currencyFont">
          <!-- 币种类型 -->
          {{ $t("label.currency.type") }}
          :{{ currency }}
        </div>
      </div>
    </div>
    <!-- 配置栏 -->
    <div class="option">
      <!-- 选择框 -->
      <div>
        <!-- 角色 -->
        <div class="roleFather">
          <div class="roleFather role" @click="openRole">
            {{ $t("label.role") }}：{{
              roleName == "" ? $t("label.role.no") : $t("label.role.select")
            }}
            <span class="roleSelect"
              >[{{ $t("label.stdashbord.xsld.business.ajssx") }}]</span
            >
          </div>
        </div>
        <span>
          <!-- 记录类型 -->
          {{ $t("label.RecordType") }}
          ：</span
        >
        <el-dropdown trigger="click">
          <div>
            <input
              type="text"
              readonly
              :value="
                recordType == ''
                  ? $t('label_partner_workspace_member_all')
                  : recordType
              "
              class="inputClass"
            />
            <div class="iconPosition el-icon-caret-bottom"></div>
          </div>
          <el-dropdown-menu>
            <div class="recordType">
              <div
                @click="choseRecord(index)"
                v-for="(item, index) in recordTypeList"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 文本框 -->
      <div class="textInput">
        <!-- 所有人 -->
        <div style="display: inline-block">
          <div style="display: inline-block">
            <span style="margin-left: 10px">
              <!-- 所有人 -->
              {{ $t("label.owner") }}
              ：</span
            >
          </div>

          <div class="positionSetting openOwner" @click="openOwner">
            <input
              style="cursor: pointer"
              v-model="ownerName"
              type="text"
              ref="role2Input"
              readonly
            />
            <svg class="icon" aria-hidden="true">
              <use href="#icon-search"></use>
            </svg>
          </div>
        </div>
        <span style="margin-left: 15px">
          <!-- 开始时间 -->
          {{ $t("label.import.index.starttime") }}
        </span>
        <div class="positionSetting">
          <el-date-picker
            v-model="startDate"
            type="date"
            :placeholder="$t('label_tabpage_selectdate')"
          >
          </el-date-picker>
        </div>
        <span style="margin-left: 10px">
          <!-- 结束时间 -->
          {{ $t("label.endtime") }}
        </span>
        <div class="positionSetting">
          <el-date-picker
            v-model="endDate"
            type="date"
            :placeholder="$t('label_tabpage_selectdate')"
          >
          </el-date-picker>
        </div>
        <button @click="getResult" class="searchButton">
          <!-- 查询 -->
          {{ $t("chatter.group.query") }}
        </button>
      </div>
    </div>
    <!-- 数据栏 -->
    <div class="data">
      <div class="dataBorder" v-loading="loadOption">
        <!-- 漏斗 -->
        <div class="hopperData">
          <!-- 漏斗图表，使用的highchart插件 -->
          <div
            class="chart_div"
            v-show="hasResult"
            ref="funnleChartsRef"
            style="width: 100%"
          ></div>
        </div>
        <!-- 业务机会汇总 -->
        <div ref="getheight" v-resize="resize" class="total">
          <div class="totalTitle">
            <!-- 业务机会汇总 -->
            {{ $t("vue_label_salesfunnel_notice1") }}
          </div>
          <table class="nowrap">
            <tr>
              <td>
                <!-- 进行中机会总数量： -->
                {{ $t("vue_label_salesfunnel_notice2") }}:
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="$t('vue_label_salesfunnel_notice3')"
                  placement="top-start"
                >
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-question"></use>
                  </svg>
                </el-tooltip>
                <div class="positionTop">{{ totalInfo.totalQuntity }}</div>
              </td>
              <td>
                <!-- 机会总金额 -->
                {{ $t("label.stdashbord.xsld.business.jhzje") }}
                ：
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="$t('vue_label_salesfunnel_notice4')"
                  placement="top-start"
                >
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-question"></use>
                  </svg>
                </el-tooltip>
                <div class="positionTop">{{ totalInfo.totalAmount }}</div>
              </td>
              <td>
                <!-- 预期总收入 -->
                {{ $t("label.stdashbord.xsld.business.yqzsr") }}
                ：
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="$t('vue_label_salesfunnel_notice5')"
                  placement="top-start"
                >
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-question"></use>
                  </svg>
                </el-tooltip>
                <div class="positionTop">
                  {{ totalInfo.expectedTotalAmount }}
                </div>
              </td>
              <td>
                <!-- 赢单总金额 -->
                {{ $t("label.stdashbord.xsld.business.qdzje") }}
                ：
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="$t('vue_label_salesfunnel_notice6')"
                  placement="top-start"
                >
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-question"></use>
                  </svg>
                </el-tooltip>

                <div class="positionTop">
                  {{ totalInfo.wonTotalAmount }}
                </div>
              </td>
              <td>
                <!-- 赢单率 -->
                {{ $t("label.stdashbord.xsld.business.ydzhl") }}
                ：
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="$t('vue_label_salesfunnel_notice7')"
                  placement="top-start"
                >
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-question"></use>
                  </svg>
                </el-tooltip>

                <div class="positionTop">{{ totalInfo.wonRate }}</div>
              </td>
            </tr>
          </table>

          <table>
            <tr style="background: #f3f2f2; font-size: 12px; letter-spacing: 0">
              <td>
                <!-- 阶段名称 -->
                {{ $t("label.stdashbord.xsld.business.jieduan") }}
              </td>
              <td>
                <!-- 机会数量 -->
                {{ $t("label.stdashbord.xsld.business.oppcounts") }}
              </td>
              <td>
                <!-- 金额 -->
                {{ $t("label.stdashbord.xsld.business.jine") }}
              </td>
              <td>
                <!-- 金额占比 -->
                {{ $t("label.stdashbord.xsld.business.jinezhb") }}
              </td>
              <td>
                <!-- 可能性 -->
                {{ $t("label.forecast.forehistory.possibility") }}
              </td>
              <td>
                <!-- 预期收入 -->
                {{ $t("label.stdashbord.xsld.business.yqsr") }}
              </td>
              <td>
                <!-- 本阶段转化率 -->
                {{ $t("label.stdashbord.xsld.business.bjdzhl") }}
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="$t('vue_label_salesfunnel_notice8')"
                  placement="top-start"
                >
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-question"></use>
                  </svg>
                </el-tooltip>
              </td>
            </tr>
            <tr v-for="(item, index) in totalList" :key="index">
              <td>{{ item.stage }}</td>
              <!-- 机会数量 -->
              <td>{{ item.quntity }}</td>
              <!-- 金额 -->
              <td>{{ item.amount }}</td>
              <!-- 金额占比 -->
              <td>{{ item.amountProportion }}</td>
              <!-- 可能性 -->
              <td>{{ item.probability }}</td>
              <!-- 预期收入 -->
              <td>{{ item.expectedRevenue }}</td>
              <!-- 本阶段转化率 -->
              <td>{{ item.stageConversion }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- 业务机会 -->
      <div class="opportanity" v-loading="loadData">
        <!-- title -->
        <div style="padding-left: 15px">
          {{ $t("lable.quote.syncing.opportunity") }}
        </div>
        <!-- 表格 -->
        <div class="tableData">
          <div class="tableTitle tableTitle2 flexPosition">
            <div>
              {{ $t("label.paging.totalofrecords", [listData.totalSize]) }}
            </div>
            <div>
              <!-- 关键字 -->
              {{ $t("label.keywords") }}
              ：<input type="text" @keyup.13="addInput" v-model="userInput" />
              <button class="btn searchButton" @click="addInput">
                <!-- 搜索 -->
                {{ $t("label.searchs") }}
              </button>
            </div>
          </div>
          <!-- view试图组件替换 -->
          <table-panel
            ref="tablePanel"
            :table-attr="headList"
            :isSalesFunnel='true'
            :page-obj="pageObj"
            :customHeader="true"
            :border="true"
            :showIndex="false"
            :objId="opportunity"
            :objectApi="objectApi"
            :showLock="false"
            :tableHeight="450"
            :sortField="orderInfo.apiname"
            :sortDir="sortDir"
            @changeSortFiled="changeSortFiled"
            @salesFunnelEdit='salesFunnelEdit'
          >
          </table-panel>
        </div>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="skippageNum"
            :page-sizes="[10, 25, 50, 100, 200, 500]"
            :page-size="pageSize"
            :layout="layout"
            :total="listData.totalSize"
            :page-count="pageCount"
          >
          </el-pagination>
          <span style="margin-left: 10px">
            {{
              $t("label.partnerCloud.partnerAccount.thePage", {
                skippageNum: skippageNum,
              })
            }}</span
          >
          <!-- 回到首页 -->
          <span class="go_homepage" @click="goHomePage">{{
            $t("label.partnerCloud.partnerAccount.backHome")
          }}</span>
        </div>
      </div>
    </div>
    <!-- 搜索对象设置 -->
    <el-dialog
      :title="$t('label.stdashbord.xsld.business.ajssx')"
      :visible.sync="showOpenRole"
      width="500px"
    >
      <shuttle-box
        ref="chooseRole"
        :roleName="roleName"
        :roleData="roleData"
        :loading='loadingRoleTree'
        :checked-keys="selectedRole"
        @cancel="closeRoleDialog"
        @deliverLength="deliverLength"
      ></shuttle-box>
    </el-dialog>
    <!-- 所有人设置 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <div
        :style="{
          'min-height': dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :dialogBodyHeight="dialogBodyHeight"
          :fieldId="fieldId"
          :checked="checkEd"
          :relevant-objid="relevantObjId"
          :relevant-objapi="relevantObjApi"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>
    <!-- 进入业务机会编辑弹窗 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="prefix"
      :id="opportunityId"
      :object-api="objectApi"
      :object-name="tabName"
      @Refreshes="Refreshes"
      @save="save"
      type="active"
    ></create-edit-obj>
  </div>
</template>

<script>
import shuttleBox from "@/components/Tree/choseRole.vue";
import TablePanel from "@/components/TablePanel/index.vue";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import Highcharts from "highcharts";
require("highcharts/modules/funnel")(Highcharts);
import {
  getRecordTypes,
  getListHeaders,
  getListData,
  getQueryFunnel,
  getRoles,
} from "./api.js";
export default {
  components: {
    shuttleBox,
    TablePanel,
    createEditObj
  },
  data() {
    return {
      opportunityId:'',
      prefix:'002',
      opportunity: "opportunity",
      objectApi: "Opportunity",
      sortDir: "asc", // 排序方式
      orderInfo: { apiname: "", order: "" },
      hasResult: true,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "ffe4102000032890C8YC",
      relevantObjId: "user",
      relevantObjApi: "User",
      relevantPrefix: "005",
      checkEd: false,
      checked: true,
      showOpenRole: false, // 是否打开选中角色树弹窗
      loadingRoleTree: false, // 角色树是否loading
      roleName: "",
      //保存已选中的角色的id
      selectedRole: [],
      selectedRoles: "",
      //所有人的id
      ownerid: "",
      // 保存记录类型名称
      recordType: "",
      //保存记录类型id名称
      recordTypeId: "all",
      // 是否可选择角色
      canChoseRole: true,
      //是否显示记录类型框
      showOpenRecord: false,
      showSearchTable: false,
      startDate: `${new Date().getFullYear()}-1-1`,
      endDate: `${new Date().getFullYear()}-12-31`,
      startShow: false,
      endShow: false,
      //保存记录类型
      recordTypeList: [],
      //保存表头字段
      headList: [],
      //保存业务汇总列表数据
      totalList: [],
      // 保存统计表格合计信息
      totalInfo: {},
      // 保存漏斗所需数据
      hopperData: [],
      // 保存下方数据
      listData: [],
      listsData: [],
      leng: 0,
      //请求数据的页数
      pno: 0,
      loadData: false,
      // 保存当前选中的阶段
      selectedProcess: "",
      userInput: "",
      showMore: false,
      ownerName: "",
      currency: "",
      newList: [],
      loadOption: false,
      pageObj: {},
      layout: " sizes, prev, pager,next",
      skippageNum: 1,
      pageSize: 10,
      pageCount: 50,
      totalSize: 0,
    };
  },
  created() {},
  directives: {
    // 使用局部注册指令的方式
    resize: {
      // 指令的名称
      bind(el, binding) {
        // el为绑定的元素，binding为绑定给指令的对象
        let width = "",
          height = "";
        function isReize() {
          const style = document.defaultView.getComputedStyle(el);
          if (width !== style.width || height !== style.height) {
            binding.value(); // 关键
          }
          width = style.width;
          height = style.height;
        }
        el.__vueSetInterval__ = setInterval(isReize, 300);
      },
      unbind(el) {
        clearInterval(el.__vueSetInterval__);
      },
    },
  },
  mounted() {
    this.loadOption = true;
    this.loadData = true;
    this.getRecordTypeList();
    this.getHeaderList();
    this.getLists(true);
    //   准备参数
    let data = {
      startdate: this.startDate,
      enddate: this.endDate,
      recordtypeid: this.recordTypeId,
    };
    this.getQueryFunnelDate(data);
  },
  methods: {
    salesFunnelEdit(item,row){
      this.$nextTick(()=>{
        this.opportunityId = row.id
        this.$refs.createEditObj.id= row.id
        this.$refs.createEditObj.edit()
      })
    },
    save(){
      this.loadData=true;
      this.getLists()
    },
    //  分页器切换分页
    handleSizeChange(val) {
      this.loadData = true;
      this.pageSize = val;
      this.getLists(false);
    },
    // 当前页码发生变化时
    handleCurrentChange(val) {
      this.skippageNum = val;
      this.loadData = true;
      this.getLists(false);
    },
    //点击回到首页按钮
    goHomePage() {
      this.loadData = true;
      this.skippageNum = 1;
      this.getLists(false);
    },
    // 排序箭头
    changeSortFiled(item) {
      if (this.orderInfo.apiname == item.apiname) {
        this.orderInfo.order = !this.orderInfo.order;
      } else {
        this.orderInfo.apiname = item.apiname;
        this.orderInfo.order = true;
      }
      this.sortDir = this.orderInfo.order ? "asc" : "desc";
      this.loadData = true;
      this.getLists(false);
    },
    jumpToDetail(item, scope) {
      let id = "";
      if (item.apiname == "name") {
        id = scope.id;
      } else {
        id = scope[item.apiname];
      }
      this.$router.push({
        path: `/commonObjects/detail/${id}/DETAIL`,
      });
    },
    nameHrefUrl(item, scope) {
      let id = "";
      if (item.apiname == "name") {
        id = scope.id;
      } else {
        id = scope[item.apiname];
      }
      return id ? `#/commonObjects/detail/${id}/DETAIL` : "";
    },
    resize() {
      this.drawHopper();
    },
    //获取角色树信息
    openRole() {
      this.showOpenRole = true;
      this.loadingRoleTree = true

      getRoles().then((result) => {
        let str = JSON.stringify([result.data]);
        //将 rolename 替换成  label
        str = str.replace(/rolename/g, "label");
        str = str.replace(/name/g, "label");
        //将 childlist 替换成 children
        str = str.replace(/childrolelist/g, "children");
        str = str.replace(/childuserlist/g, "children");
        str = str.replace(/roleid/g, "id");
        this.roleData = JSON.parse(str);
        this.$refs.chooseRole.roleData = this.roleData;
        this.loadingRoleTree = false
      });
    },
    //  获取所有人
    openOwner() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },
    // 收起选择角色树弹出框
    closeRoleDialog() {
      this.showOpenRole = false;
    },
    // 选中角色后在销售漏斗中回显选中的数据
    deliverLength(result) {
      if (result.length > 0) {
        this.roleName = "hasRole";
        this.selectedRole = result;
        this.ownerName = "";
        this.ownerid = "";
      } else {
        this.roleName = "";
        this.selectedRole = [];
      }
      this.getResult();
    },
    //获取记录类型
    async getRecordTypeList() {
      let result = await getRecordTypes();
      this.recordTypeList = result.data.recordtypeList;
    },
    //获取表头字段
    async getHeaderList() {
      let result = await getListHeaders();
      this.headList = result.data;
      this.headList.map((item) => {
        item.nameLabel = item.fieldLabel;
        item.schemefieldName = item.apiname;
        item.schemefieldType = item.fieldType;
        // item.modify=item.edit
        if (item.fieldType === "S" || item.fieldType === "Y") {
          item.click = "detail";
        }
      });
      this.leng = this.headList.length;
      this.length = result.data.length;
    },
    // 封装绘制销售漏斗的方法
    drawHopper() {
      this.hopperData = [];
      for (var key in this.newList) {
        let arr = {};
        arr.name = this.newList[key].stage;
        arr.y = Number(this.totalList[key].quntity);
        arr.id = this.newList[key].value;
        arr.className = this.totalList[key].amountProportion;
        this.hopperData.push(arr);
      }
      let heiGht = this.$refs.getheight.offsetHeight;
      var opt = {
        chart: {
          type: "funnel",
          marginRight: 150,
          marginTop: 0,
          height: heiGht - 60,
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: "",
          x: -50,
        },
        colors: ["#01D4FF", "#A1DB51", "#FFC643", "#FF696B"],
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          allowPointSelect: true,
          series: {
            cursor: "pointer",
            events: {
              click: (event) => {
                let name = event.point.name;
                let array = this.newList.filter((item) => {
                  return item.stage == name;
                });
                this.selectedProcess = array[0].stageId;
                this.loadData = true;
                this.pno = "1";
                // 设置滚动条的高度为0
                // this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.getLists(false);
              },
            },
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "none",
              format:
                "<b>{point.name}</b> <br> ({point.id}/<br>{point.className})",
              color: "black",
              softConnector: true,
            },
            neckWidth: "30%",
            neckHeight: "15%",
            height: "90%",
            width: "90%",
          },
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            name: "",
            data: [],
          },
        ],
        credits: {
          enabled: false, //不显示logo
        },
      };
      opt.series[0].data = this.hopperData;
      Highcharts.chart(this.$refs.funnleChartsRef, opt);
    },
    //  获取上层数据
    async getQueryFunnelDate(data) {
      let result = await getQueryFunnel(data);
      if (!result.data) {
        this.$message.error("系统异常，请联系管理员");
      } else {
        // this.hopperData=[]
        this.currency = result.data.currency;
        this.totalList = result.data.totalOpportunities;
        this.totalInfo = result.data.totalInfo;
        this.newList = result.data.funnelData.funnelDataList;
        this.drawHopper();
        if (this.hopperData.length == 0) {
          this.hasResult = false;
        } else {
          this.hasResult = true;
        }
      }
      this.loadOption = false;
    },
    showStart() {
      this.startShow = true;
    },
    choseStart() {
      setTimeout(() => {
        this.startShow = false;
      }, 200);
    },
    choseRecord(index) {
      this.recordType = this.recordTypeList[index].name;
      this.recordTypeId = this.recordTypeList[index].id;
      this.getResult();
    },
    //拼接时间
    getDate(date) {
      if (!date) {
        return "";
      } else {
        var array = [];
        if (typeof date !== "string") {
          array.push(date.getFullYear());
          array.push(date.getMonth() + 1);
          array.push(date.getDate());
          return array.join("-");
        } else {
          return date;
        }
      }
    },
    getResult() {
      this.startDate = this.getDate(this.startDate);
      this.endDate = this.getDate(this.endDate);
      let startDate = this.startDate.split("-");
      let endDate = this.endDate.split("-");
      let result = true;
      if (Number(startDate[0]) < Number(endDate[0])) {
        result = true;
      } else if (Number(startDate[0]) == Number(endDate[0])) {
        if (Number(startDate[1]) < Number(startDate[1])) {
          result = true;
        } else if (Number(startDate[1]) == Number(endDate[1])) {
          if (Number(startDate[2]) <= Number(endDate[2])) {
            result = true;
          } else if (Number(startDate[2]) > Number(endDate[2])) {
            result = false;
          }
        } else if (Number(startDate[1]) > Number(endDate[1])) {
          result = false;
        }
      } else if (Number(startDate[0]) > Number(endDate[0])) {
        result = false;
      }
      if (!result) {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.weixin.end.later.start"),
        });
        return;
      }
      if (this.selectedRole.length != 0) {
        this.selectedRoles = this.selectedRole.toString();
      } else {
        this.selectedRoles = "";
      }
      this.loadOption = true;
      this.getLists(true);
      let object = {
        enddate: this.endDate,
        startdate: this.startDate,
        recordtypeid: this.recordTypeId,
        searchKeyWord: this.userInput,
        roleids: this.selectedRoles,
        ownerid: this.ownerid,
      };
      this.getQueryFunnelDate(object);
    },
    //  获取业务机会列表数据
    async getLists(process) {
      if (process == true) {
        this.selectedProcess = "";
      }
      let obj = {
        enddate: this.endDate,
        startdate: this.startDate,
        jieduan: this.selectedProcess,
        recordtypeid: this.recordTypeId,
        page: this.skippageNum,
        pageSize: this.pageSize,
        searchKeyWord: this.userInput,
        roleids: this.selectedRoles,
        ownerid: this.ownerid,
        sort: this.orderInfo.apiname,
        dir: this.orderInfo.order ? "asc" : "desc",
      };
      let result = await getListData(obj);
      this.loadData = false;
      if (!result.data) {
        this.$message.error(
          this.$i18n.t("vue_label_email_contact_adminstrator")
        );
      } else {
        this.listData = result.data;
        var array = result.data.list;
        this.listsData = [];
        this.listsData = array;
        let resultList = [];
        this.listsData.map((item) => {
          let obj = {};
          this.headList.map((Sitem) => {
            let str = "";
            if (Sitem.fieldType == "Y") {
              str = Sitem.apiname + "ccname";
            } else {
              str = Sitem.apiname;
            }
            obj[str] = item[str];
          });
          for (var Ikey in item) {
            if (!obj[Ikey]) {
              obj[Ikey] = item[Ikey];
            }
          }
          resultList.push(obj);
        });
        this.listsData = resultList;
        this.listsData.map((item) => {
          item.type = "sellhoper";
        });
        this.pageObj.dataList = this.listsData;
        this.headList.map((item) => {
          item.nameLabel = item.fieldLabel;
          item.schemefieldName = item.apiname;
          item.schemefieldType = item.fieldType;
          if (item.apiname === "name") {
            item.modify = "true";
          }
          if (item.fieldType === "S" || item.fieldType === "Y") {
            item.click = "detail";
          }
        });
        this.$nextTick(() => {
          this.$refs.tablePanel &&
            this.$refs.tablePanel.changeTableAttr(this.headList);
          this.$refs.tablePanel.dataList = this.pageObj.dataList;
        });
        if (result.data.list.length < 20) {
          this.showMore = true;
        } else {
          this.showMore = false;
        }
      }
    },
    addInput() {
      this.loadData = true;
      this.pno = "1";
      this.getLists(false);
    },
    changeSelect(data) {
      if (data.length !== 0) {
        this.ownerid = data.data.id;
        this.ownerName = data.data.name;
        this.roleName = "";
      } else {
        this.ownerid = "";
        this.ownerName = "";
      }
      this.showSearchTable = false;
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  background: #006dcc;
  border-radius: 3px;
  color: #ffffff;
  height: 22px;
  border: none;
  font-size: 12px;
  cursor: pointer;
}
.btn:focus {
  outline: none;
}
.canChose {
  color: #006dcc;
}
.noChose {
  color: #dddbda;
}
.canChoseInput {
  background: #ffffff;
}
.hopper {
  margin: 10px;
  border-radius: 4px;
  background-color: #ffffff;
}
.title {
  background: #fafaf9;
  padding: 10px;
  display: flex;
  border-bottom: 1px solid #dddbda;
}
.title img {
  width: 36px;
  height: 36px;
}
.textTitle {
  margin-left: 10px;
  margin-top: -3px;
  height: 36px;
}
.textTitle div {
  font-family: SourceHanSansCN-Medium;
  font-size: 16px;
  color: #080707;
}
.option {
  background: #fafaf9;
  padding: 10px;
  border-bottom: 1px solid #dddbda;
}
input {
  background: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  height: 22px;
}
.iconPosition {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.searchButton {
  height: 23px;
  line-height: 23px;
  background-color: #006dcc;
  color: #ffffff;
  border: none;
  border-radius: 2px;
  margin-left: 10px;
  cursor: pointer;
  padding: 0px 11px;
  font-size: 12px;
  margin-top: -1px;
}
.hopperData {
  width: 40%;
  min-height: 450px;
  border-right: 1px solid #dddbda;
  position: relative;
}
.total {
  width: 60%;
  min-height: 450px;
  vertical-align: top;
}
input:focus {
  outline: none;
}
.recordTypeResult {
  position: absolute;
  left: 30px;
  top: 235px;
  width: 300px;
  height: 150px;
  border-radius: 4px;
  background: purple;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
  margin-left: 10px;
}
::v-deep .el-input__icon {
  line-height: 25px;
}
.recordType {
  cursor: pointer;
}
.recordType div {
  padding: 5px 15px;
}
.recordType div:hover {
  background-color: #ecf5ff;
  color: #7ac2fd;
}
.tableTitle {
  font-family: SourceHanSansCN-Medium;
  font-size: 12px;
  color: #333333;
  background: #f3f2f2;
  border-bottom: 1px solid #dddbda;
}
.tableTitle2 {
  display: flex;
  height: 40px;
  padding-left: 5px;
  padding-top: 10px;
}
table {
  width: 100%;
  font-family: SourceHanSansCN-Medium;
  color: #333333;
}
table tr {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #dddbda;
  font-size: 12px;
}
td {
  min-width: 70px;
  max-width: 150px;
}
.footer {
  /* display: flex; */
  width: 100%;
  height: 40px;
  background: #f3f2f2;
  border-bottom: 1px solid #dddbda;
}
.footer div {
  display: inline-block;
  border-right: 1px solid #dddbda;
  line-height: 40px;
}
.opportanity {
  padding: 10px;
  font-family: SourceHanSansCN-Medium;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
}
.table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tableData {
  padding: 10px;
  width: 100%;
}
td {
  padding-left: 20px !important;
}
.totalTitle {
  display: table;
  font-size: 14px;
  padding-left: 20px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #dddbda;
}
.chart_div {
  padding-left: -10px;
  position: absolute;
  left: 0px;
  top: 30px;
}
.option {
  ::v-deep .el-input__inner {
    cursor: pointer;
    height: 25px;
  }
}
::v-deep .el-table {
  font-size: 12px;
}
::v-deep .el-table td,
.el-table th {
  padding: 8px 0;
  overflow-x: hidden;
}
::v-deep .el-table .cell {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.allowClick {
  color: #006dcc;
  text-decoration: solid;
  cursor: pointer;
}
a {
  color: #000;
  cursor: auto;
}
::v-deep .allowClick.is-leaf {
  color: #3e3e3c;
  cursor: auto;
}
::v-deep .el-table thead {
  color: #3e3e3c;
}
.nowrap td {
  white-space: pre-wrap;
  word-wrap: break-word;
}
::v-deep .title .icon {
  width: 36px;
  height: 36px;
}
.tableData input {
  height: 23px;
}
::v-deep .icon {
  display: inline-block;
}
::v-deep .openOwner .icon {
  margin-left: -23px;
  cursor: pointer;
}
.currencyFont {
  font-size: 12px !important;
}
.roleFather {
  display: inline-block;
  margin-right: 30px;
}
.role {
  margin-left: 10px;
}
.roleSelect {
  margin-left: 15px;
  color: #006dcc;
  cursor: pointer;
}
.inputClass {
  padding-left: 10px;
  cursor: pointer;
}
.textInput {
  position: relative;
  margin-top: 10px;
}
.positionSetting {
  display: inline-block;
}
.dataBorder {
  display: flex;
  border-bottom: 1px solid #dddbda;
}
.positionTop {
  margin-top: 5px;
}
.nowrap tr {
  height: 60px;
}
.flexPosition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
}
::v-deep .el-table .caret-wrapper {
  display: none;
}
.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 9;
  border: 1px solid #dddbda;
}
.go_homepage {
  margin-left: 20px;
  cursor: pointer;
  color: #006dcc;
}
</style>
